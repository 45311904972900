$(document).ready(function () {
  var animalId = 0;
  var animalSelected = [];
  var occupationSelected = [];
  $('#animal_filters input').click(function () {
    console.log($('#animal_filters input:checked').length);
    console.log($('#occupation_filters input:checked').length);
    if (
      $('#animal_filters input:checked').length == 0 &&
      $('#occupation_filters input:checked').length == 0
    ) {
      animalSelected = [];
      occupationSelected = [];
      resetFilters();
    } else {
      animalId = $(this).val();
      if ($(this).is(':checked')) {
        animalSelected.push(animalId);
      } else {
        animalSelected = jQuery.grep(animalSelected, function (value) {
          return value != animalId;
        });
      }
      if ($('.camera').length > 0) {
        filterCameras(animalSelected, 0);
      } else if ($('.broadcaster').length > 0) {
        filterBroadcasters(animalSelected, 0);
      }
    }
  });
  $('#occupation_filters input').click(function () {
    if (
      $('#animal_filters input:checked').length == 0 &&
      $('#occupation_filters input:checked').length == 0
    ) {
      animalSelected = [];
      occupationSelected = [];
      resetFilters();
    } else {
      occupationId = $(this).val();
      if ($(this).is(':checked')) {
        occupationSelected.push(occupationId);
      } else {
        occupationSelected = jQuery.grep(occupationSelected, function (value) {
          return value != occupationId;
        });
      }
      if ($('.broadcaster').length > 0) {
        filterBroadcasters(occupationSelected, 1);
      }
    }
  });
  $('#reset').click(function () {
    resetFilters();
  });
  if (window.location.href.indexOf('diffuseurs') > -1 || window.location.href.indexOf('broadcasters') > -1) {
    var param = getSearchParams('search');
    if (param && param.length > 0) {
      filterBroadcastersByName(param);
    } else {
      $('#emptySearch').hide();
    }
  }
});
var filterCameras = function (selected, type) {
  $('.camera').each(function () {
    if (type == 0) {
      var animal = $(this).data('animal').toString();
      if ($.inArray(animal, selected) != -1) {
        $(this).show();
      } else {
        $(this).hide();
      }
    }
  });
};
var filterBroadcasters = function (selected, type) {
  $('.broadcaster').each(function () {
    if (type == 1) {
      if ($(this).data('occupation')) {
        var value = $(this).data('occupation').toString();
        if ($.inArray(value, selected) != -1) {
          $(this).parent().parent().show();
        } else {
          $(this).parent().parent().hide();
        }
      } else {
        $(this).parent().parent().hide();
      }
    } else if (type == 0) {
      if ($(this).data('animal')) {
        var values = $(this).data('animal').toString().split(',');
        const found = selected.some((r) => values.indexOf(r) >= 0);
        if (found) {
          $(this).parent().parent().show('slow');
        } else {
          $(this).parent().parent().hide('slow');
        }
      } else {
        $(this).parent().parent().hide('slow');
      }
    }
  });
};
var filterBroadcastersByName = function (str) {
  var found = 0;
  var value = '';
  $('.broadcaster').each(function () {
    str = str.toLowerCase();
    value = $(this).data('name').toString().toLowerCase();
    if (value.indexOf(str) > -1) {
      $(this).parent().parent().show();
      found = 1;
    } else {
      $(this).parent().parent().hide();
    }
  });
  if (found == 0) {
    $('#emptySearch').show();
  } else {
    $('#emptySearch').hide();
  }
};
var resetFilters = function () {
  $('input').each(function () {
    $(this).prop('checked', false);
  });
  if ($('.camera').length > 0) {
    $('.camera').each(function () {
      $(this).parent().parent().show();
    });
  } else if ($('.broadcaster').length > 0) {
    $('.broadcaster').each(function () {
      $(this).parent().parent().show();
    });
  }
};
var getSearchParams = function (k) {
  var p = {};
  location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
    p[k] = v;
  });
  return k ? p[k] : p;
};
